<template>
  <img :src="this.backgroundImgSrc" style="top:0; left:0; z-index: -1; position: fixed; height:100vh; width:100vw; object-fit: cover"  alt=""/>
  <div class="login-container">
    <div class="container-logo">
      <img alt="company logo" :src="this.logoImgSrc">
    </div>
    <h1>{{ $t('login') }}</h1>
    <p>{{ $t('pleaseEnterYourAccessDataToContinue') }}</p>

    <div v-if="domain.toLowerCase() === 'gleason'" class="organisation-wrapper">
      <span>{{$t('placeholderSelectCompany')}}</span>
      <a @click="this.selectCompanyAlert">{{company}}</a>
    </div>

    <AppInput @input-changed="emailChanged" :label="$t('form.person.email')" type="email" :placeholder="this.$t('placeholderEnterEmail')"></AppInput>
    <AppInput @keyup.enter.exact="login" @input-changed="passwordChanged" :label="$t('password')" type="password"
              :placeholder="this.$t('placeholderEnterPassword')"></AppInput>

    <div class="login-wrapper">
      <primary-btn @click="login" :val="$t('login')"></primary-btn>
      <div class="bottom-link-wrapper">
        <a @click="forgotPassword">{{$t('reset')}}</a>
        <a @click="loginWithUsername">{{$t('loginWithCode')}}</a>
      </div>
    </div>

  </div>
  <Loader :show="this.showLoader"></Loader>

  <Modal :val="this.$t('back')" :heading="this.$t('loginWithCode')" @close-form="modalClosed" :modal-is-open=modalIsOpen>
    <LoginWithUserID @emit-login="loginWithCode" @close-form="modalClosed"></LoginWithUserID>
  </Modal>
</template>

<script>
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import AppInput from "../../components/form/inputs/AppInput";
import Amplify, { Auth } from 'aws-amplify';
import Loader from "@/components/loader/Loader";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import awsGleasonCH from "@/aws-exports-gleason-ch";
import awsmobile from "@/aws-exports";
import Modal from "@/components/modal/Modal";
import LoginWithUserID from "@/components/auth/LoginWithUserID";
import {Config} from "../../config";

export default {
  components: {LoginWithUserID, AppInput, PrimaryBtn, Loader,Modal},
  name: 'Login',
  mixins:[SweetAlerts],

  data() {
    return {
      email: '',
      password: '',
      showLoader: false,
      selectCompany:{GleasonLB:"Gleason GPM", GleasonCH:"Gleason GSAG"},
      modalIsOpen:false,
      logoImgSrc: Config.Logo.link,
      backgroundImgSrc: Config.BackgroundImage.link,
      domain: Config.Names.domain
    }
  },

  computed:{
    company(){
      return this.$store.getters.company;
    },
  },

  methods:{
    loginWithCode(payload){
      this.email = payload.username;
      this.password = payload.password;
      this.login();
    },

    login() {
        this.showLoader = true;

      /**
       * Inside 4learning we have two different cognito pools.
       * One is the default and one is for gleason ch only.
       * If we have a domain that is not the gleason organisation
       * then we can load the default config. Otherwise, we have to check
       * if it is gleason ch or gleason. If it is gleason ch, then we have to
       * load the ch config, otherwise we keep default.
       */
      if (this.domain.toLowerCase() !== "gleason"){
            Amplify.configure(awsmobile);
        } else if(this.company === "Gleason GSAG"){
          Amplify.configure(awsGleasonCH)
        } else{
          Amplify.configure(awsmobile);
        }

        Auth.signIn(this.email, this.password).then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            Auth.completeNewPassword(
                user,               // the Cognito User Object
                prompt("enter new password"),       // the new password
            ).then(() => {
              // at this time the user is logged in if no MFA required
              this.$router.push({
                name:'Home',
              });
            }).catch(e => {
              console.log(e);
            });
          }
            this.$store.dispatch("getCredentials").then(() => {
              this.showLoader = false;

              Auth.currentAuthenticatedUser().then(res => {
                if (res) this.goToIndex();
              }).catch(e=>{
                console.log(e);
              });
            });
          }).catch(e => {
            const error = e.message;
            if(error.includes("security reasons")) this.showErrorMessage(this.$t('securityError'));
            if(error.includes("Incorrect username or password")) this.showErrorMessage(this.$t('usernameOrPasswordWrongError'));
            if(error.includes("cannot be empty")) this.showErrorMessage(this.$t('emailEmptyError'));
            if(error.includes("Custom auth lambda trigger")) this.showErrorMessage(this.$t('passwordEmptyError'));

            this.showLoader = false;
          });
    },

    loginWithUsername(){
      this.modalIsOpen = !this.modalIsOpen;
    },

    forgotPassword(){
      // Collect confirmation code and new password, then
      this.showPromptMessage(this.$t('forgotPassword'), this.$t('enterVerifiedEmail')).then(res => {
        if (res.isConfirmed){
          const username = res.value.toLowerCase();
          Auth.forgotPassword(username)
              .then(() => this.completeCodeChallenge(username))
              .catch(err => {
                this.showErrorMessage(err.message);
                const error = err.message;
                if(error.includes("cannot be empty")) this.showErrorMessage(this.$t('emailEmptyError'));
              });
        }
      })
    },

    completeCodeChallenge(username){
      this.showPromptMessage(this.$t('verificationCode'), this.$t('enterVerificationCode')).then(res => {
        if (res.isConfirmed){
          const code = res.value;
          this.enterNewPassword(username,code);
        }
      });
    },

    // Entering the new password and submitting it to the server.
    enterNewPassword(username,code){
      this.showPromptPassword(this.$t('newPassword'), this.$t('enterNewPassword')).then(res => {
        if (res.isConfirmed) {

          //Checking if the password contains the requirement set. (8 characters,1 uppercase, 1 number & 1 special character)
          const newPassword = res.value;
          const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
          const match = passwordRegex.test(newPassword);

          // If it matches, we submit the new password and check if the code is correct.
          if (match) {
            Auth.forgotPasswordSubmit(username, code, newPassword)
                .then(() => this.showSuccessMessage(this.$t('passwordResetSuccess')))
                // Catching the error and informing the user that, the verification code was wrong.
                .catch(err => {
                  const error = err.message;
                  if(error.includes("Invalid verification code")) this.showErrorMessage(this.$t('invalidVerificationCodeError'));
                })
            // The typed password doesn't fit the requirement set, so we show an alert if the user wants to type a new password.
          } else {
            this.$swal({
              title: this.$t('passwordDoesntFitCriteria'),
              text: this.$t('setNewPasswordAgain'),
              icon: 'warning',
              type: "warning",
              confirmButtonColor: "#007AC2",
              confirmButtonText: this.$t('yes'),
              showCancelButton: true,
              cancelButtonText: this.$t('no'),
            }).then((res) => {
              if (res.isConfirmed) this.enterNewPassword(username, code);
            })
          }
        }
      })
    },

    modalClosed(){
      this.modalIsOpen = !this.modalIsOpen;
    },

    emailChanged(event){
      this.email = event.target.value;
    },

    passwordChanged(event){
      this.password = event.target.value;
    },

    /**
     * Somehow for cognito to work correctly and set all tokens
     * etc. an initial reload is needed after we log in.
     */
    goToIndex(){
      this.$router.push({
        name:'Home',
      });
    },

    selectCompanyAlert(){
      this.$swal({
        title: this.$t('selectCompany'),
        input: 'select',
        inputOptions: this.selectCompany,
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        allowOutsideClick: false
      }).then((res) => {
        if(res.isConfirmed) {
          Auth.signOut();

          this.$store.commit("setCompany",this.selectCompany[res.value]);
          this.$store.commit("setCredentials",{ "username": null,
              "jwtToken": null,
              "friendlyName": null,
              "role": null,
              "email":null,
              "group":null})

          window.location.reload();
        }
      })
    }
  },

  mounted() {
    Auth.currentUserInfo().then(res => {
      if (res){
        this.$router.push({
          "name": "Home"
        })
      } else {
        if(!this.company){
          if (Config.Names.domain.toLowerCase() === "codevance"){
            this.$store.commit("setCompany","codevance");
            localStorage.setItem("company", "codevance");
          } else {
            this.$store.commit("setCompany","Gleason GPM");
            localStorage.setItem("company", "Gleason GPM");
          }
        }
      }
    })
  }
}

</script>

<style scoped>

  h1{
    margin-top: 40px;
    margin-bottom: 24px;
  }

  a{
    text-decoration: none;
    color: var(--primary-color);
    font-size: 14px;
  }

  a:hover {
    text-decoration: underline;
  }

  .login-container {
    width: 400px;
    border: 1px solid var(--brightgrey);
    box-shadow: lightgrey 0 0 0 0, lightgrey 0 2px 4px;
    padding: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color:white;
    border-radius:10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .login-wrapper{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .login-container p {
    margin-bottom: 24px;
  }

  .container-logo{
    display: flex;
    justify-content: start;
  }

  .container-logo > img{
    width: 125px;
    height:auto;
  }

  .organisation-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px
  }

  .bottom-link-wrapper{
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 600px) {
    .login-container {
      width:auto!important;
      transform: inherit;
      top: 0;
      bottom: 0;
      border-radius: 0;
      padding: 80px 20px 20px 20px;
      margin-top: 0;
    }

    .bottom-link-wrapper{
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 400px) {
    .login-wrapper {
      flex-direction: column-reverse;
      align-items: inherit;
    }
  }

</style>
