
<!--
  unused css ist ignored, because classes inside app.vue are being used
  throughout the page.
-->
<!--suppress CssUnusedSymbol -->
<template>
      <div id="nav" v-if="notInsideIFrame">
        <Topnav v-if="!this.$route.path.includes('login')"/>
        <Sidebar v-if="!this.$route.path.includes('login')"/>
        <Loginnav v-if="this.$route.path.includes('login')"/>
        <router-link to="/">
          <img class="img" alt="logo" :src="this.logoSrc" v-if="this.$route.path !== '/login'">
        </router-link>
      </div>
      <main>
        <div :style="windowWidth > 600 && notInsideIFrame ? {'margin-left': sidebarWidth } : '80'">
          <router-view v-if="username" v-slot="{Component}" >
              <keep-alive exclude="SingleviewCourse,SingleviewArticle">
                <component :is="Component"></component>
              </keep-alive>
          </router-view>
          <router-view v-else></router-view>
        </div>
      </main>
      <footer v-if="!this.$route.path.includes('login')">
        <custom-footer></custom-footer>
      </footer>
      <ScrollTopArrow></ScrollTopArrow>
</template>

<script>
  import Sidebar from "./components/sidebar/Sidebar";
  import ScrollTopArrow from "./components/buttons/scroll-top/ScrollTopArrow";
  import { sidebarWidth } from "./components/sidebar/state";
  import Topnav from "./components/topnav/Topnav";
  import Loginnav from "./components/auth/Loginnav";
  import customFooter from "./components/footer/customFooter";
  import i18n from "./i18n";
  import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
  import {Config} from "./config";

  export default {
    name: '4learning Webapp',
    components: {
      Loginnav, Topnav, Sidebar, customFooter, ScrollTopArrow
    },
    mixins:[SweetAlerts],
     created() {
      this.$store.dispatch("getCredentialsFromLocalStorage");

      /*create const of locale and use localStorage.getItem to see if we have anything
      saved in storage with name locale.*/
      const locale = localStorage.getItem('locale');
      /*if we do, we set the i18n locale to be this value.*/
      if(locale)i18n.locale = locale;

    },
    setup() {
      return { sidebarWidth }
    },
    data() {
      return {
        windowWidth: window.innerWidth,
        logoSrc: Config.Logo.link
      }
    },
    watch: {
      username:{
        handler(){
          if(this.token && this.username){
            this.$store.dispatch("getCourses");
          }
        },
      },
    },
    mounted() {
      // When we focus the page we commit reloadNecessary in store and check if 2h went by when the site was mounted.
      window.addEventListener('focus',()=>this.$store.commit('reloadNecessary',new Date().getTime()));
      // When the internet connection is removed, we inform the user with a sweet-alert.
      window.addEventListener('offline', ()=>{this.showErrorMessage("Ihre Internetverbindung wurde unterbrochen")});
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
      // We add the current time + 2h in localStorage.
      const reloadTimer = new Date().getTime() + 28800000 ;
      localStorage.setItem('reloadNecessary',reloadTimer)
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      onResize() {
        this.windowWidth = window.innerWidth
      },
    },
    computed:{
      token(){
        return this.$store.getters.jwtToken;
      },
      username(){
        return this.$store.getters.username;
      },
      // Returns true if we aren't inside an iFrame.
      notInsideIFrame(){
        return window.location === window.parent.location
      }
    }
  }
</script>

<style>

  :root {
    --primary-color: #007AC2;
    --darkgrey: #555;
    --brightgrey: #f1f2f3;
    --grey: #c8cacc;
    --bright: #fff;
    --delete: #BA3B3B;
  }

  html{
    background-color: white;
  }

  a:active{
    color: var(--primary-color);
  }

  a:visited{
    color: inherit;
  }

  h1, h2,h3,h4, h5, h6, p, li {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul, li {
    padding-inline-start: 0;
    list-style: none;
  }

  html, body{
    margin: 0;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  /*Managed to show the images in view -
  this is a more quick fix then a solution*/
  .img{
    position: absolute;
    left: 55px;
    top: 24px;
    max-height: 50px;
    max-width: 250px;
  }

  #nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  button:hover, a:hover{
    cursor: pointer;
  }

  #app {
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .nav a {
    border-bottom: 1px solid var(--bright);
    color: var(--darkgrey);
    padding: 12px 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .nav a:hover{
    box-shadow: 0 0 5px rgb(169 191 203);
  }

  .nav a.router-link-exact-active {
    background-color: var(--primary-color);
    color: white;
    fill: white;
  }

  .ql-editor, .ql-toolbar.ql-snow{
    background-color: white;
  }

  .router-link-exact-active svg {
    fill: white;
  }

  .nav svg {
    margin-right: 12px;
  }

  .main-content-container {
    margin: 100px 40px 80px 40px;
  }

  .singleview-content-container {
    margin: 40px;
  }

  .singleview-content-container-header{
    margin: 75px 0 24px 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
      }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
  }

  /* Changed default styling for sweetalert box (ignore unused highlighting)*/
  .swal2-title {
    font-family: Helvetica, Arial, sans-serif !important;
    font-style: normal;
  }

  .swal2-html-container {
    font-family: Helvetica, Arial, sans-serif !important;
  }

  .swal2-styled.swal2-confirm {
    background-color: var(--primary-color)!important;
    border-radius: 4px !important;
  }

  .swal2-cancel.swal2-styled.swal2-cancel{
    border-radius: 4px !important;
  }

  .swal2-styled.swal2-deny {
    border-radius: 4px !important;
  }

  .multiselect{
    min-height: 32px!important;
  }

  @media screen and (max-width: 600px) {
    .main-content-container {
      margin: 100px 15px 80px 65px;
    }

    h1{
      font-size: 20px!important;
    }

    h2{
      font-size: 18px!important;
    }

    .img{
      display: none;
    }
    input[type = "file"]{
      width: 100%;
    }
  }

</style>
