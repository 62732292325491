<template>
  <button>{{ val }}</button>
</template>
<script>
  export default {
    name: 'CancelBtn',
    props: {
      val: String
    }
  }
</script>
<style scoped>

  button{
    background-color: white;
    border: 1px solid var(--darkgrey);
    padding: 10px 24px;
    font-size: 14px;
    /*font-weight: bold;*/
    color: var(--darkgrey);
    border-radius: 4px;
  }

  button:hover{
    cursor: pointer;
    box-shadow: 0 0 5px rgb(169 191 203);
  }

</style>