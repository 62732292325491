/**
 * The config file manages variables that change depending on the domain.
 * @type {{Names: {appName: string}, PrivacyRemark: {link: string}, BackgroundImage: {color: string, link: string},
 * TermsOfUse: {link: string}, Cookies: {domain: string}, AboutUs: {link: string}, Logo: {link: string, video: string},
 * Contact: {link: string}}}
 */
export const Config = {
    Cookies:{
        domain: "https://learning.4service.io"
    },
    Names:{
        appName: "4Learning",
        domain: "codevance"
    },
    BackgroundImage:{
        link: "https://public-storage-codevance.s3.eu-central-1.amazonaws.com/codevance/image.jpg",
        color: ""
    },
    Logo:{
        link: "https://public-storage-codevance.s3.eu-central-1.amazonaws.com/codevance/logo.svg"
    },
    AboutUs:{
        link: "https://codevance.de",
        email: "info@codevance.de"
    },
    TermsOfUse: {
        link : "https://www.codevance.de/legal-notice"
    },
    Certificate:{
        fullCompanyName: "Codevance Software Development GmbH",
        city: "Renningen"
    },
    Access:{
        has3DRoom: false
    }
};
