<template>
  <div v-if="courseDeleted" class="search-result-empty">
    <span>{{this.$t('courseDeleted')}}</span>
  </div>
  <div v-if="this.course">
    <div
        :style="{'background-image':'url(https://www.hdwallback.net/wp-content/uploads/2017/12/white-backgrounds-hd-1000x900.jpg)'}"
        class="singleview-course-header" :class="notInsideIFrame ? 'singleview-content-container-header' : ''">
      <div v-if="notInsideIFrame" class="path-container">
        <router-link :to="redirectedFrom">{{ redirectedFrom }}</router-link>
        <span>
       >
      </span>
        <router-link v-if="this.course" to="">{{ this.course.title }} ({{ this.course.id }})</router-link>
      </div>
      <div class="course-header-wrapper" v-if="this.course.length !== 0">
        <div v-if="this.course">
          <h1>{{ course.title }}</h1>
        </div>
        <div class="singleViewCardContainer">
          <Card :displayContextMenuBtn="false" :course-object="this.course" creator="Creator" date="Creation date"/>
        </div>
      </div>
    </div>
    <div class="singleview-content-container">
    <span :title="$t('recommendCourseTo')">
      <svg @click="recommendCourse()" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="var(--primary-color)"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"/></svg>
    </span>
      <span :title="$t('calendar.add')">
      <svg @click="openModal()" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="var(--primary-color)"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z"/></svg>
    </span>
      <TabNav :selected="selected"
              :tabs="[$t('tab.course.content'), $t('tab.course.description'), $t('tab.course.discussion')]"
              @selected="setSelected">
        <Tab :is-selected="selected === $t('tab.course.content')">
          <div style="margin-bottom:48px; margin-top: 60px">
            <h2>{{ $t('tab.course.content') }}</h2>
          </div>
          <div class="overview-headline-wrapper">
            <div v-if="learningProgress > -1">
              <h3>{{ $t('progress') }}</h3>
              <ProgressBar :value="calculatedStatus"></ProgressBar>
            </div>
            <div v-if="allowCertificateDownload && isCourseLive">
              <secondary-btn @click="this.createCertificate" :val="this.$t('downloadCertificate')"></secondary-btn>
            </div>
          </div>
          <div style="margin-left:5px;" v-if="isCourseLive">
            <span v-if="this.remainingAttempts  && this.calculatedStatus === 100">{{this.$t('remainingAttempts')}}: {{this.remainingAttempts}}</span>
          </div>
          <div style="margin-bottom: 30px;" v-if="isCourseLive">
            <secondary-btn v-if="this.remainingAttempts > 0 && this.calculatedStatus === 100" :val="this.$t('restartCourse')" @click="restartCourse" style="margin-top: 20px"></secondary-btn>
            <span v-if="this.calculatedStatus === 100 && this.course.course.rating.hasCertificate && this.allowCertificateDownload" style="margin-top:20px;margin-left: 5px;font-weight: bold">{{this.$t('courseCompleted')}}</span>
            <span v-else-if="this.calculatedStatus === 100 && this.course.course.rating.hasCertificate && !this.allowCertificateDownload" style="margin-top:20px;color:red;margin-left: 5px;font-weight: bold">{{this.$t('courseFailed')}}</span>
            <span v-else-if="this.calculatedStatus === 100" style="margin-top:20px;margin-left: 5px;font-weight: bold">{{this.$t('courseCompleted')}}</span>
          </div>

          <div v-if="calculatedStatus < 100" style="margin-top: 24px; margin-bottom: 12px; display: flex; justify-content: space-between">
            <div v-if="isCourseLive">
              <PrimaryBtn @click="handleStartCourse" :val="getBtnLabel()"></PrimaryBtn>
            </div>
          </div>
          <div v-if="isCourseLive">
            <ContentPreviewContainer :course="this.course.course" :learningProgress="this.learningProgress"
                                     @template-clicked="(module, template) => showInGallery(module,template)">
            </ContentPreviewContainer>
          </div>
        </Tab>
        <Tab :is-selected="selected === $t('tab.course.description')">
          <div style="margin-bottom:40px; margin-top: 60px">
            <h2>{{ $t('tab.course.description') }}</h2>
          </div>
          <ContentEditor :description="this.course.course.description"/>
        </Tab>
        <Tab :is-selected="selected === $t('tab.course.discussion')">
          <div style="margin-bottom:24px; margin-top: 60px">
            <h2>{{ $t('tab.course.discussion') }}</h2>
          </div>
          <CommentSection :comments="this.course.course.comments" :course="this.course.course" :courseId="course.id"></CommentSection>
        </Tab>
      </TabNav>
    </div>

    <CourseGallery v-if="galleryIsOpen" @learning-progress-changed="learningProgressChanged"
                   :learningProgress="this.learningProgress" @gallery-closed="this.closeGallery"
                   :active-module="this.activeModule" :active-template="this.activeTemplate"
                   :course-prop="this.course.course" :course-data-prop="this.course" :modalIsOpen="galleryIsOpen"
                   @open-next-module="this.showInGallery">
    </CourseGallery>

    <Modal :val="this.$t('back')" @close-form="closeModal" :heading="$t('calendar.add')" :modal-is-open=modalIsOpen>
      <CalendarPopup @calendar-changed="this.updateCalendarList"></CalendarPopup>
    </Modal>
    <Loader :show="this.showLoader"></Loader>
  </div>

</template>

<script>

import Card from "../components/course/card/Card";
import TabNav from "../components/tabs/TabNav";
import Tab from "../components/tabs/Tab";
import ContentEditor from "../components/course/singleview-content/ContentEditor";
import CourseGallery from "../components/course-gallery/CourseGallery";
import PrimaryBtn from "../components/buttons/PrimaryBtn";
import '/src/assets/css/calendar.css'
import ContentPreviewContainer from "../components/content-preview-container/content-preview-container";
import Loader from "@/components/loader/Loader";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import ProgressBar from "../components/course-gallery/progress-bar/ProgressBar";
import CommentSection from "../components/discussion/CommentSection";
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";
import Modal from "@/components/modal/Modal";
import CalendarPopup from "../components/course/course-manager/CalendarPopup";
import Uuidv4Generator from "@/mixins/uuid/Uuidv4Generator";
import {UploadIndicator} from "@/mixins/upload-indicator/upload-indicator";
import SecondaryBtn from "@/components/buttons/SecondaryBtn";
import createPDF from "@/mixins/pdf-creator/createPDF";

export default {
  name: "SingleviewCourse",
  mixins: [SweetAlerts, Uuidv4Generator,createPDF],
  components: {
    SecondaryBtn,
    CommentSection,
    ProgressBar,
    Loader,
    ContentPreviewContainer,
    PrimaryBtn,
    CourseGallery,
    ContentEditor,
    Card,
    Tab,
    TabNav,
    Modal,
    CalendarPopup,
  },
  data() {
    return {
      modalIsOpen: false,
      selected: this.$t('tab.course.content'),
      galleryIsOpen: false,
      // the course we are currently accessing
      // the active template of the course, whose index
      // is equal to the learning progress
      activeTemplate: Object,
      // the active module, which serves as template container
      activeModule: Object,
      isCourseLive: false,
      showLoader: false,
      // the learning progress is a number between 0 and n templates
      // that were completed
      learningProgress: -1,
      redirectedFrom: "/library",
      remainingAttempts: 0,
      personList: [],
      uploadIndicator: document.body.appendChild(new UploadIndicator()),
      sortBy: 'friendlyName',
      sortDirection: 'asc',
      courseId: ""
    }
  },

  computed: {
    /**
     * Returning our singleCourse from the store.
     * If the course is available, we look for specific properties
     * and change our data.
     * **/
    course(){
      const course = this.$store.getters.courses.find((foundCourse) => foundCourse.id === this.courseId);

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if(course) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isCourseLive = course["course"]["publicationStatus"] === "20";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if(course.course.rating.maxAttempts) this.remainingAttempts = course.course.rating.maxAttempts

        if(course.progress) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          if(course.progress.remainingRestarts) this.remainingAttempts = course.progress.remainingRestarts.toString() ? course.progress.remainingRestarts : course.course.rating.maxAttempts;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.learningProgress = course.progress && Object.keys(course.progress).length > 0 ? parseInt(course.progress.learningProgress) : -1;
        return course;
      }
      return null;
    },

    courseDeleted(){
      return this.$store.getters.courseDeleted;
    },

    // Returns true if we aren't inside an iFrame.
    notInsideIFrame(){
      return window.location === window.parent.location
    },

    /**
     * Calculates the completion status of this course. We will
     * sum up all templates (learning steps) and calculate the percentage
     * of the learning progress (if any - otherwise we return 0).
     * @returns {string|number}
     */
    calculatedStatus() {
      if (this.learningProgress !== -1) {
        let totalSteps = 0;
        for (let module of this.course.course.courseModules) {
          totalSteps = totalSteps + module.courseTemplates.length;
        }
        if(Math.round(this.learningProgress / (totalSteps) * 100) >100){
          return 100;
        }else {
          return Math.round(this.learningProgress / (totalSteps) * 100);
        }
      }
      return 0;
    },

    /**
     * this will return the module that we are currently located,
     * using the learning progress. Basically we iterate through all
     * modules and increase the counter until it is equal to the
     * learning progress. Then we return that module.
     * @returns {null|*}
     */
    learningProgressModuleAndTemplate() {
      let counter = 0;
      if (this.course.course.courseModules) {
        for (let module of this.course.course.courseModules) {
          // eslint-disable-next-line no-unused-vars
          for (let template of module.courseTemplates) {
            if (counter === this.learningProgress) {
              return {
                "module": module,
                "template": template
              };
            }
            counter++;
          }
        }
      }

      return {
        "module": null,
        "template": null
      };
    },

    courseCompletionRate() {
      if(this.course) {
        let totalPercentage = 100;
        let totalQuestions = this.course.progress.questions ? (this.course.progress.questions).length : 1;
        let wrongAnswerPercentage = 100 / totalQuestions;
        // Iterating through questions-array and subtracting a percentage for each wrong answer
        if(this.course.progress.questions){
          for (let item of this.course.progress.questions) {
            if (item.status === "failed") {
              totalPercentage = totalPercentage - wrongAnswerPercentage
            }
          }
        }
        return Math.round(totalPercentage);
      }
      return 0;
    },

    // sorting personList-array by friendlyName
    sortedPersonList() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.personList.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === 'desc') modifier = -1;
        if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
        if (p1[this.sortBy] > p2[this.sortBy]) return modifier;
        return 0;
      });
    },

    /**
     * If we get a course from the store, we check
     * if the course has the certificate Boolean set.
     * Then we check if the course is finished and if so we return
     * a Boolean to see if the user can download the certificate or not.
     * @returns {boolean}
     */
    allowCertificateDownload(){
      if(this.course.length !== 0) {
        // if this course was closed, then a certificate might be available for download.
        // first we check if this course grants a certificate at all.
        if (this.course.course.rating.hasCertificate) {
          if (this.calculatedStatus === 100) {
            // if it does, we now check if a certain rating was needed
            if (this.course.course.rating.hasRating) {
              return this.courseCompletionRate >= this.course.course.rating.ratingPercentage;
            } else {
              return true;
            }
          }
        }
      }
      return false;
    },
  },

  methods: {
    // creating the certificate for the user.
    createCertificate(){
        this.createPDFCertificate()
      },

    // sets the selected tab.
    setSelected(tab) {
      this.selected = tab;
    },

    // closes the gallery component.
    closeGallery() {
      this.galleryIsOpen = false;
    },
    // Opens the Modal component.
    openModal() {
      this.modalIsOpen = true;
    },

    // Recommending a course
    recommendCourse() {
      // Shows an alert with all persons we can select to recommend the current course
      this.$swal({
        title: this.$t('recommendCourseTo'),
        input: 'select',
        inputOptions: this.personList,
        inputPlaceholder: this.$t('placeholderRecommendCourse'),
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
      }).then((res) => {

        if (res.isConfirmed && res.value) {

          // Creating a notifications object.
            const initials = this.$store.getters.friendlyName.substring(0,2);

            const postObject = {
              "id": this.createUuidv4(),
              "receiver": res.value,
              "sender": this.$store.getters.username,
              "notification": {
                "initials": initials,
                "intent": "course_info",
                "intentId": this.createUuidv4(),
                "message_de": this.$store.getters.friendlyName + " hat dir den Kurs " + this.course.course.title + " empfohlen",
                "message_en": this.$store.getters.friendlyName + " has suggested the course " + this.course.course.title + " for you",
                "courseId": this.course.id,
              }
            }

            this.showLoader = true;
            // Posting the notification to the server
          this.$store.dispatch("postRequest",{
            "param":"postNotification",
            "payload":postObject
          }).then(() => {
              this.showLoader = false;
              this.showSuccessMessage(this.course.course.title + this.$t('recommended'));
            })
        }
      });
    },

    // Closes the Modal component.
    closeModal() {
      this.modalIsOpen = false;
    },
    // After receiving the data from the component "CalendarPopup", we push it into the CalendarView
    updateCalendarList(calendarEntry) {
      this.modalIsOpen = false;
      this.showLoader = true;
      this.$store.dispatch("postRequest",{
        "param":"addCalendarEvent",
        "payload":calendarEntry
      }).then(() => {
        this.$store.commit("addNewEvent",calendarEntry);
        this.showSuccessMessage(this.$t('courseSuccessfullyAddedIntoCalendar'))
        this.showLoader = false;
      });
    },

    isObjectEmpty() {
      return this.course.course && Object.keys(this.course.course).length === 0
          && Object.getPrototypeOf(this.course.course) === Object.prototype;
    },

    // Shows the total learning steps a course has to offer.
    getTotalLearningSteps() {
      let counter = 0;
      for (let module of this.course.course.courseModules) {
        counter = counter + module.courseTemplates.length;
      }
      return counter;
    },

    sort(s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortBy = s;
    },

    /**
     * Depending on our learning progress we will either
     * continue, start or subscribe the / to the course as
     * primary button label.
     * TODO: beautify & translate
     * @returns {string}
     */
    getBtnLabel() {
      if (this.learningProgress > -1) {
        if (this.learningProgress > 0) {
          return this.$t('continue');
        } else {
          return "Start";
        }
      } else {
        return this.$t('enrol');
      }
    },

    // Restarting the entire course and deleting all progress.
    restartCourse(){
       this.showConfirmationDialogue(this.$t('restartCourse'),this.$t('restartCourseText') + this.remainingAttempts + " " + this.$t('remainingAttempts')).then((res) => {
         if (res) {

            // Decreasing the remaining Attempts the user can restart the course.
            this.remainingAttempts--;

            const postObject = {
              "courseId": this.course.id,
              "userId": this.$store.getters.username,
              "learningProgress": 0,
              "remainingRestarts": this.remainingAttempts.toString(),
              "subscriptionTimestamp":this.course.progress.subscriptionTimestamp,
            }

           this.$store.dispatch("postRequest", {
             "param": "restartCourse",
             "payload": postObject
           }).then(()=>{
             this.$store.commit("updateLearningProgress",postObject);
           });
         }
       });
    },

    /**
     * We can either subscribe to the course, start the course,
     * or continue, depending on the current learningProgress.
     */
    handleStartCourse() {
      if (this.learningProgress > -1) {
        // check if the course was already completed.
        if (this.learningProgress === this.getTotalLearningSteps()) {
          return this.showInfoMessage(this.$t('courseCompleted'), this.$t('completedCourseInfo'));
        }
        // continue or start course
        this.showInGallery(this.learningProgressModuleAndTemplate.module, this.learningProgressModuleAndTemplate.template);
      } else {
          this.showLoader = true;
          this.$store.dispatch("postRequest",{
            "param":"subscribeToCourse",
            "payload":{
              "courseId": this.course.id,
              "courseTitle": this.course.course.title,
              "userId": this.$store.getters.username,
              "subscriptionTimestamp": new Date().getTime().toString()
            }
          }).then(() => {

            const course = {
              "course": this.course.course,
              "domain": this.$store.getters.domain,
              "id": this.course.id,
            }
            this.$store.commit("addMyCourse",course);

            this.showLoader = false;
            const postObject = {
              "courseId":this.course.id,
              "learningProgress":0,
              "subscriptionTimestamp": new Date().getTime().toString()
            }
            this.$store.commit("updateLearningProgress",postObject)
          })
      }
    },

    /**
     * Whenever we go to the next template inside the course gallery
     * and the learning progress increases, we will receive a
     * "learning progress changed" event.
     * @param progress
     */
    learningProgressChanged(progress) {
      this.learningProgress = progress;
      this.$store.commit("learningProgressChanged", {courseId: this.courseId, learningProgress:progress});
    },

    /**
     * Fired when a template is supposed to be shown
     * inside the gallery. We provide the template index
     * and the module. The learning progress will be passed
     * as separate prop.
     * @param module
     * @param template
     */
    showInGallery(module, template) {
      // first we need to make sure that we have already subscribed to the course
      if (this.learningProgress === -1) {
        this.showInfoMessage(this.$t('notSubscribed'), this.$t('subscribeFirst'));
      }
      this.activeModule = module;
      this.activeTemplate = template;
      this.galleryIsOpen = true;
    },

    /**
     * Each course has a title image. All course images are safely stored
     * inside S3 using the path: course/:courseId/image.jpeg. If no image
     * is found, then we will return a default image.
     * TODO: make sure we are allowed to use this image or replace it.
     * @param courseId
     * @returns {Promise<String>}
     */
    getTitleImgSrc(courseId) {
      return new Promise((resolve) => {
        const prefix = "course/" + courseId + "/";
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          if (res.length > 0) {
            s3FileManager.getSignedURL(prefix + "image.jpeg").then(url => {
              resolve(url);
            })
          } else {
            resolve("https://annsvg.com/the-content/uploads/2021/03/E-Learning_gr-1.jpg");
          }
        })
      })
    },
  },

  mounted() {
    // inside the router we have a dynamic placeholder inside the route:
    // /library/course:id. So whatever param we find at id, we assume
    // it is the course that we would like to see.
    const routerParams = this.$router.currentRoute.value.params;
    this.courseId = routerParams.id;

    this.$store.dispatch("updateSingleCourse",this.courseId);

    this.showLoader = true;

    this.$store.dispatch("getRequest","getUsers").then(res => {
      this.personList = JSON.parse(res.body);
      this.sortedPersonList;
      this.showLoader = false;

      // Mapping personList, so we only see the name or the user instead of all information.
      const persons = [];
      for (let person of this.personList) {
        persons.push({
          "id": person.id,
          "friendlyName": person.friendlyName,
        })
      }
      const personsMap = {};

      persons.map((o) => {
        personsMap[o.id] = o.friendlyName;
      })
      this.personList = personsMap;
    })
  },

}
</script>

<style scoped>

.search-result-empty{
  font-size: 24px;
  text-align: center;
  margin-top: 500px;
}

.overview-headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-wrapper:hover span {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.template-wrapper:hover .template-type-wrapper svg {
  fill: var(--primary-color);
}

.path-container {
  margin-bottom: 24px;
  font-size: 12px;
}

.singleview-course-header {
  padding: 40px;
  background-color: #cccccc;
}

.course-header-wrapper {
  max-width: 400px;
  overflow: auto;
}

.singleViewCardContainer {
  position: absolute;
  top: 120px;
  right: 40px;
  max-width: 300px;
  z-index: 1;
}

svg {
  width: 32px;
  height: 32px;
}

svg:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media screen and (max-width: 1000px) {
  .singleViewCardContainer {
    position: unset;
    margin-left: auto;
    max-width: 270px;
    margin-top: 24px;
  }

  .singleview-course-header {
    padding-bottom: 24px;
  }

  .course-header-wrapper {
    max-width: none;
  }

  .singleViewCardContainer {
    max-width: none;
  }

  .singleview-content-container {
    margin-top: 50px;
  }

}

@media screen and (max-width: 800px) {
  .overview-headline-wrapper {
    display: block;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .singleview-course-header {
    padding: 10px 10px 10px 60px;
  }

  .singleview-content-container {
    margin: 40px 10px 0 65px;
  }
}
</style>
