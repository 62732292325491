<template>
  <div class="modal-container">
    <div v-if="modalIsOpen">
      <div class="overlay">
        <div ref="elem" class="modal" :style="removePadding ? 'overflow-y:hidden' : ''">
            <span v-if="notInsideIFrame" :style="removePadding ? 'background-color: white' : ''" @click="cancelForm" class="close-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path
                  d="M0 0h24v24H0z" fill="none"/><path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
            </span>

          <span v-if="notInsideIFrame" :style="removePadding ? 'background-color: white' : ''" class="close-fullscreen-icon" @click="switchFullscreenMode();">
            <svg v-if="!fullscreen" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path
                d="M0 0h24v24H0z" fill="none"/><path
                d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path
                  d="M0 0h24v24H0z" fill="none"/><path
                  d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/></svg>
            </span>


          <h2 v-if="!removePadding"> {{ heading }} </h2>
          <div>
            <slot></slot>
          </div>
          <div>
            <cancel-btn class="cancel-btn" @click="cancelForm" :val="val"></cancel-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CancelBtn from "../buttons/CancelBtn";

export default {
  components: {
    CancelBtn
  },
  data() {
    return {
      isOpen: this.modalIsOpen,
      fullscreen: false,
      removePadding:this.removePaddingProp,
    };
  },
  computed:{
    notInsideIFrame(){
      return window.location === window.parent.location
    },
    computedRemovePadding(){
      if(this.removePadding) return '0'
      return '40px 40px 80px 40px'
    }
  },
  methods: {
    cancelForm() {
      this.$emit("close-form");
    },

    switchFullscreenMode() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen === true) {
        this.openFullscreen();
      } else {
        this.closeFullscreen();
      }
    },
    /* Function to open fullscreen mode */
    openFullscreen() {
      let elem = this.$refs.elem
      if (elem.msRequestFullscreen) { /* IE/Edge */
        elem = window.top.document.body; //To break out of frame in IE
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }


    },

    /* Function to close fullscreen mode */
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        window.top.document.msExitFullscreen();
      }
      const closeIcon = document.querySelector('.close-icon');
      closeIcon.style.display = "block";
    },

    exitHandler() {
      if (document.webkitIsFullScreen === false) {
        this.fullscreen = false;
      }else if (document.mozFullScreen === false) {
        this.fullscreen = false;
      }else if (document.msFullscreenElement === false) {
        this.fullscreen = false;
      }
    }
},
  props: {
    heading: String,
    body: String,
    modalIsOpen: Boolean,
    childComponents: Array,
    val: String,
    removePaddingProp:Boolean,
  },

  mounted(){
    /**
     * creating eventListener when pressing esc to change the fullscreen icon.
     */
    document.addEventListener('fullscreenchange', this.exitHandler,false);
    document.addEventListener('mozfullscreenchange', this.exitHandler,false);
    document.addEventListener('MSFullscreenChange', this.exitHandler,false);
    document.addEventListener('webkitfullscreenchange', this.exitHandler,false);

  }
};
</script>

<style scoped>
.modal {
  margin: 20px;
  overflow: auto;
  padding: v-bind('computedRemovePadding');
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 1001;
  overflow: auto;
}

h2 {
  margin-bottom: 24px;
}

.cancel-btn {
  position: fixed;
  left: 64px;
  bottom: 64px;
}

.close-icon {
  position: fixed;
  right: 30px;
  top: 30px;
}

.close-icon:hover svg, .open-fullscreen-icon:hover svg, .close-fullscreen-icon {
  fill: var(--primary-color);
  cursor: pointer;
}

.close-fullscreen-icon {
  position: fixed;
  right: 55px;
  top: 30px;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 20px;
  }

  .cancel-btn {
    left: 40px;
    bottom: 40px;
  }

  .close-icon {
    right: 20px;
    top: 20px;
  }

  .close-fullscreen-icon {
    right: 45px;
    top: 20px;
  }
}

</style>
