export default {
  "nav.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten"])},
  "nav.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek"])},
  "nav.courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Kurse"])},
  "nav.calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
  "nav.persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
  "nav.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "nav.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "selectCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs wählen"])},
  "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])},
  "objectCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Objekte"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "notifications.markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle als gelesen markieren"])},
  "card.readmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr lesen"])},
  "advertisedCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beworbener Kurs"])},
  "newTitleImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Titelbild"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "saveExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und Zurück"])},
  "createdCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angelegte Kurse"])},
  "usedMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belegter Speicher"])},
  "createdUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angelegte Benutzer"])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unbegrenzt"])},
  "groupExtern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Gruppe"])},
  "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlich"])},
  "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "pleaseEnterYourAccessDataToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre Zugangsdaten ein, um fortzufahren."])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "elearningSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Fragen zu Inhalten einzelner Kurse wenden Sie sich bitte an den jeweiligen Autor des Kurses. Sie benötigen Unterstützung im Umgang mit der E-Learning Lernplattform? Prüfen Sie bitte zunächst die im Intranet zur Verfügung gestellte Dokumentation. Weitere Fragen richten Sie bitte per E-Mail an "])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
  "courseDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs Details"])},
  "deleteModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul löschen"])},
  "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template hinzufügen"])},
  "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template bearbeiten"])},
  "deleteTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Template löschen ?"])},
  "goToCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kurs"])},
  "editPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post bearbeiten"])},
  "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen"])},
  "deleteComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar löschen"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
  "deleteCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs löschen"])},
  "deletePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post löschen"])},
  "selectStepType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie ein Template aus."])},
  "placeholderSelectStepType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template auswählen"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
  "courseHasRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs hat Bewertung"])},
  "courseHasCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer/innen erhalten Zertifikat"])},
  "successfulAttendanceQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieviel % der Fragen, müssen Teilnehmer/innen mind. richtig beantworten?"])},
  "swal.confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher? Sie können diesen Vorgang nicht rückgängig machen!"])},
  "swal.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie sich ausloggen?"])},
  "addSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erfolgreich hinzugefügt."])},
  "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erfolgreich gelöscht."])},
  "swal.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erfolgreich aktualisiert"])},
  "resetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich neugestartet"])},
  "enterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen Titel ein."])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage"])},
  "imageMarker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildmarkierung"])},
  "fileUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hochladen"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "enrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einschreiben"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt"])},
  "button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "button.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "button.change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
  "course.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs hinzufügen"])},
  "course.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs bearbeiten"])},
  "unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungespeicherte Veränderungen"])},
  "unsaved.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es könnten bearbeitende Veränderungen vorhanden sein. Durch das Bestätigen, werden die Veränderungen nicht gespeichert. Sind Sie sicher, dass Sie zurück gehen wollen ?"])},
  "compulsory.course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflicht"])},
  "external.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für externe Kunden zugänglich"])},
  "organisational.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für interne Organisationen zugänglich"])},
  "module.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul hinzufügen"])},
  "module.editName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul Name bearbeiten"])},
  "tab.course.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursinhalt"])},
  "tab.course.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursbeschreibung"])},
  "tab.course.discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diskussion"])},
  "feed.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Post"])},
  "person.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person hinzufügen"])},
  "person.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person bearbeiten"])},
  "group.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe hinzufügen"])},
  "group.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])},
  "group.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
  "tag.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag hinzufügen"])},
  "tag.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag löschen"])},
  "select.grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid-Ansicht"])},
  "select.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen-Ansicht"])},
  "form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "form.title.tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
  "form.creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
  "form.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "form.duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer (in min)"])},
  "form.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
  "form.publicationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "form.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt"])},
  "form.upload.file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hochladen"])},
  "form.person.friendlyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "form.person.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "form.person.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
  "form.person.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "form.person.persNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalnummer"])},
  "form.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "form.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "form.person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "mandatoryCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtkurs"])},
  "titleImage.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titelbild hochladen"])},
  "question.correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtig"])},
  "question.wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsch"])},
  "footer.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
  "footer.help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
  "footer.terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
  "deleteEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "learningProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lernfortschritt"])},
  "currentLearnStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Lernschritt"])},
  "courseCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursanzahl"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
  "selectPublicationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursstatus (10 = Inaktiv, 20 = Live)"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versuche"])},
  "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbleibende Versuche"])},
  "noRemainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine verbleibenden Versuche mehr. Bitte zum nächsten Template weiter gehen."])},
  "assignCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs zuweisen"])},
  "emailConfirmationHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer hat seine E-Mail Adresse noch nicht verifiziert."])},
  "convertToCodeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Codenutzer konvertieren"])},
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen"])},
  "submitAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort einreichen"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "generateLearningInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lernübersicht generieren"])},
  "calendar.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kalender hinzufügen"])},
  "dateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfang (Datum)"])},
  "timeStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfang (Uhrzeit)"])},
  "dateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende (Datum)"])},
  "timeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende (Uhrzeit)"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs beenden bis"])},
  "calendarSubmitEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge dürfen nicht leer sein"])},
  "calendarDateSubmitWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsdatum oder -zeit ist nach dem Enddatum"])},
  "courseSuccessfullyAddedIntoCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs erfolgreich zum Kalender hinzugefügt."])},
  "selectVideoType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videotyp auswählen"])},
  "insertYTLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube-Link einfügen"])},
  "insertLinkToVideoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge den Link ein, der zum Originalvideo führt"])},
  "answerOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwortmöglichkeiten"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade eine Datei von deinem Computer hoch"])},
  "uploadVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade ein Video von deinem Computer hoch"])},
  "uploadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade ein PDF von deinem Computer hoch"])},
  "videoType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videotyp"])},
  "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Versuche"])},
  "placeholderResponsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlichen auswählen"])},
  "placeholderSelectCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs auswählen"])},
  "placeholderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel eintragen"])},
  "placeholderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung eintragen"])},
  "placeholderMaxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Versuche eintragen"])},
  "placeholderEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail eintragen"])},
  "placeholderEnterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort eintragen"])},
  "placeholderCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor eintragen"])},
  "placeholderGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe eintragen"])},
  "placeholderTagName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag eintragen"])},
  "placeholderFriendlyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name eintragen"])},
  "placeholderPersNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalnummer eintragen"])},
  "placeholderCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma eintragen"])},
  "placeholderRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle eintragen"])},
  "placeholderDateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum eintragen"])},
  "placeholderDateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum eintragen"])},
  "placeholderTimeStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeit eintragen"])},
  "placeholderTimeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endzeit eintragen"])},
  "placeholderDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer eintragen"])},
  "placeholderQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage eintragen"])},
  "placeholderAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort eintragen"])},
  "placeholderFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback eintragen"])},
  "placeholderYoutubeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Link eintragen"])},
  "placeholderVideoSrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videoquelle eintragen"])},
  "placeholderPublicationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursstatus auswählen"])},
  "placeholderRecommendCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person auswählen"])},
  "placeholderSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "placeholderPersonId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "resetMarker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markierung zurückstellen"])},
  "enableDrag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markierung bewegen"])},
  "recommendCourseTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs weiterempfehlen an: "])},
  "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" wurde weiterempfohlen"])},
  "resendInvitationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungslink erneut senden"])},
  "resentInvitationLinkSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungslink erneut gesendet"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
  "enterVerifiedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ihre verifizierte E-Mail Adresse ein"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierungscode"])},
  "enterVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Verifizierungscode ein, welcher Ihnen per E-Mail geschickt wurde"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein neues, starkes Passwort (Groß- Kleinschreibung & min. 8 Charaktere & 1 Sonderzeichen)  ein"])},
  "notSubscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht eingeschrieben"])},
  "subscribeFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte schreiben Sie sich in den Kurs ein, mit dem Knopf oben links."])},
  "courseCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs abgeschlossen"])},
  "courseFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchgefallen"])},
  "completedCourseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben diesen Kurs bereits abgeschlossen."])},
  "finishPrevStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beenden sie die vorhergehenden Schritte, bevor Sie zugreifen wollen auf: "])},
  "lookForLearningIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Suchen Sie das gelbe Lernicon oder klicken Sie oben rechts auf Continue, um auf Ihr momentanes Template zu gelangen."])},
  "visitWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuchen Sie unsere neue Webseite"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt besucht"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offen"])},
  "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benachrichtigungen"])},
  "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "finishedModuleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben dieses Modul abgeschlossen."])},
  "startNextModuleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie mit dem nächsten Modul anfangen ?"])},
  "finishedLastModuleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben diesen Kurs abgeschlossen."])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort erfolgreich gesetzt."])},
  "fileExceedSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei ist größer als 5MB."])},
  "noFileFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datei gefunden."])},
  "convertPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF in ein Modul konvertieren"])},
  "selectPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine PDF-Datei aus"])},
  "showCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Zertifikat öffnen ?"])},
  "certificateNotUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zertifikat wurde noch nicht hochgeladen"])},
  "downloadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat herunterladen"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefällt mir"])},
  "subscriptionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eingeschrieben am"])},
  "completionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abgeschlossen am"])},
  "restartCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs neustarten"])},
  "restartCourseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie den Kurs neustarten möchten ? Sie haben noch "])},
  "restartCourseCompletely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie für diesen Benutzer den Kurs komplett neustarten ?"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschrift"])},
  "emptyInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Eingabefeld darf nicht leer sein."])},
  "recommendedForMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für mich empfohlen"])},
  "commentAlreadyLiked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihnen gefällt dieser Kommentar bereits"])},
  "emptyComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare dürfen nicht leer sein"])},
  "exportToExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Excel exportieren"])},
  "exportToExcelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Tabelle in Excel exportieren ? Achtung, das kann eine Weile dauern."])},
  "noSearchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse gefunden."])},
  "cantDeleteYourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht ihren eigenen User löschen."])},
  "cantDeleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie dürfen keine Gruppe löschen, in der es noch Personen gibt."])},
  "addAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwortmöglichkeit hinzufügen"])},
  "courseDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Kurs existiert nicht mehr."])},
  "courseInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Kurs ist momentan deaktiviert."])},
  "passwordDoesntFitCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Passwort stimmt nicht mit den Richtlinien überein. (Groß- Kleinschreibung & min. 8 Charaktere & 1 Sonderzeichen)"])},
  "setNewPasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie ein neues Passwort erneut eingeben ?"])},
  "invalidVerificationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkorrekter Verifizierungscode bereitgestellt, bitte versuchen Sie es erneut."])},
  "emailEmptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail darf nicht leer sein."])},
  "passwordEmptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort darf nicht leer sein."])},
  "usernameOrPasswordWrongError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username oder Passwort sind falsch."])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person verifizieren"])},
  "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuladen"])},
  "reloadPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite wurde lange nicht neu geladen und es könnten neue Inhalte verfügbar sein. Bitte laden Sie die Website neu."])},
  "rightsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Aktionen haben Sie nicht die erforderlichen Rechte."])},
  "securityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login aus Sicherheitsgründen nicht möglich. Bitte Passwort zurücksetzen."])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie ihren Firmensitz aus."])},
  "placeholderSelectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "loginWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Code einloggen"])},
  "codePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code eingeben"])},
  "registerWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Code registrieren"])},
  "pleaseCopyCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Zugangsdaten werden nur einmal angezeigt! Bitte drücken Sie auf 'Logindaten kopieren'."])},
  "createdId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellte Id: "])},
  "createdPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelltes Passwort: "])},
  "cantEditCreatedUsersWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User die mit einem Code erstellt wurden, können nicht bearbeitet werden."])},
  "copyCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logindaten kopieren"])},
  "oneTimeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmaliger Nutzer"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der 3D-Raum wird momentan erstellt"])},
  "open3DRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D-Raum öffnen"])},
  "liveCourseModulesIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für einen Live-Kurs muss mindestens ein Module mit einem Template erstellt werden."])}
}