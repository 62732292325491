<template>
  <div class="main-content-container">
    <div class="course-header-container">
      <div>
        <h1 style="margin-bottom: 24px;">{{ $t("nav.library") }} ({{sortedCourseList.length}})</h1>
      </div>
      <div class="course-header-wrapperLeft">
        <div>
          <select v-model="selected" @change="switchSelect($event)" name="course-view" id="course-view">
            <option value="grid-view">{{ $t("select.grid") }}</option>
            <option value="list-view">{{ $t("select.list") }}</option>
          </select>
        </div>
        <div v-if="selected === 'grid-view'" class="course-header-wrapperLeft">
          <div class="sorting-container">
            <select v-model="sortBy" @change="switchSort($event)">
              <option value="title" :class="[sortBy === 'title' ? sortDirection : '']">
                {{ this.$t('title') }}
              </option>
              <option value="duration" :class="[sortBy === 'duration' ? sortDirection : '']">
                {{ this.$t('duration') }}
              </option>
              <option value="publicationStatus" :class="[sortBy === 'publicationStatus' ? sortDirection : '']">
                {{ this.$t('form.publicationStatus') }}
              </option>
              <option value="recent" :class="[sortBy === 'recent' ? sortDirection : '']">
                {{ this.$t('recent') }}
              </option>
              <option value="groups" :class="[sortBy === 'groups' ? sortDirection : '']">
                {{this.$t('recommendedForMe')}}
              </option>
            </select>
            <svg v-if="this.sortDirection === 'asc'" @click="changeSorting" xmlns="http://www.w3.org/2000/svg"
                 height="48px" viewBox="0 0 24 24" width="25px" fill="#555">
              <path d="M0 0h24v24H0V0z" fill="none"/>
              <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"/>
            </svg>
            <svg v-if="this.sortDirection === 'desc'" @click="changeSorting" xmlns="http://www.w3.org/2000/svg"
                 height="48px" viewBox="0 0 24 24" width="25px" fill="#000">
              <path d="M0 0h24v24H0V0z" fill="none"/>
              <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"/>
            </svg>
          </div>
          <div>
            <label>
              <input type="search" :placeholder="this.$t('placeholderSearch')" v-model="searchQuery">
            </label>
          </div>
        </div>
      </div>
    </div>
    <PrimaryBtn v-if="this.isAdmin" @click="editOrCreateCourse({})" :val="$t('course.add')"></PrimaryBtn>
    <div style="max-height: 70vh;overflow-y: auto;" v-show="selected === 'grid-view'" class="card-container">
      <div v-for="(item, index) of resultQuery" :key="item">
        <Card :my-domain-name="getDomainName" :displayContextMenuBtn="true" :course-object="item" :learning-progress="item.progress"
              @edit-course="editOrCreateCourse(item)" @delete-course="removeCourse(item, index)"
              @courseClicked=courseClicked(item)>
        </Card>
      </div>
    </div>
    <div v-if="searchResultIsEmpty || resultQuery.length === 0" class="search-result-empty">
      <span>{{this.$t('noSearchResult')}}</span>
    </div>
    <div v-if="selected === 'list-view'">
      <MaterialTable @item-viewed="courseClicked" :item-view="itemView"
                     :table-headers="tableHeaders" :table-rows="tableRows" :inside-group-table="false"></MaterialTable>
    </div>
  </div>
  <Modal :val="this.$t('back')" @close-form="this.closeCourseModal()" :heading="this.$options.courseModalHeader"
         :modal-is-open=modalIsOpen>
    <AddCourseForm @course-changed="this.updateCourseList" :is-new-course="isNewCourse"
                   :course="this.course"></AddCourseForm>
  </Modal>
  <Loader :show="this.showLoader"></Loader>
</template>

<script>
import Card from "../components/course/card/Card";
import Modal from "../components/modal/Modal";
import PrimaryBtn from "../components/buttons/PrimaryBtn";
import AddCourseForm from "../components/course/course-manager/CoursePopup";
import Loader from "@/components/loader/Loader";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";
import Uuidv4Generator from "../mixins/uuid/Uuidv4Generator";
import MaterialTable from "@/components/material-table/MaterialTable";
import {UploadIndicator} from "@/mixins/upload-indicator/upload-indicator";
import {Config} from "../config";

export default {
  name: 'Library',
  courseModalHeader: "Kurs bearbeiten",
  mixins: [SweetAlerts, Uuidv4Generator],
  components: {MaterialTable, AddCourseForm, PrimaryBtn, Card, Modal, Loader},

  data: () => ({
    modalIsOpen: false,
    // we can select grid, or list-view
    selected: 'grid-view',
    course: {},
    sortBy: 'title',
    sortDirection: 'asc',
    // data returned from the search input elmnt
    searchQuery: null,
    searchResultIsEmpty: false,
    showLoader: false,
    isNewCourse: true,
    isAdmin: false,
    recentCourseList: [],
    itemView: true,
    // indicator that shows up when uploading items.
    uploadIndicator: document.body.appendChild(new UploadIndicator()),
    domainHref: Config.Cookies.domain
  }),

  methods: {
    editOrCreateCourse(course) {
      // if the object is empty, it means that we create a new course and
      // we can open the course popup with an empty course object.
      if (this.isObjectEmpty(course)) {
        this.$options.courseModalHeader = this.$t('course.add');
        this.isNewCourse = true;
        this.course = {
          "rating":{},
          "id": this.createUuidv4(),
        };
        this.modalIsOpen = true;
      } else {
        // otherwise we are editing a course and we are retrieving the
        // latest data first.
        this.course = course.course;
        this.$options.courseModalHeader = this.$t('course.edit');
        this.isNewCourse = false;
        this.modalIsOpen = true;
      }
    },

    closeCourseModal() {
      this.$swal({
        title: this.$t('unsaved'),
        text: this.$t('unsaved.content'),
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
      }).then((result) => {
        if(result.isConfirmed){
          this.modalIsOpen = false;
          this.course = {};
        }
      });
    },

    /**
     * Checks if the course object is empty.
     * @returns {boolean}
     */
    isObjectEmpty(course) {
      return course && Object.keys(course).length === 0
          && Object.getPrototypeOf(course) === Object.prototype;
    },

    updateCourseList(course) {
      this.showLoader = true;
      this.$store.commit("updateCourse",{
        "course":course,
        "isNewCourse":this.isNewCourse
      })

      if(this.isNewCourse){
        this.showSuccessMessage(this.$t('course') + ' ' + this.$t('addSuccess'));
      }else{
        this.showSuccessMessage(this.$t('course') + ' ' + this.$t('swal.updateSuccess'));
      }
      this.modalIsOpen = false;
      this.showLoader = false;
    },

    removeCourse(course) {
      this.showConfirmDialogue(this.$t('deleteCourse'), this.$t('swal.confirmDelete')).then(() => {
        const fileManager = new S3FileManager();
        const path = "course/" + course.id + "/";

        // inside the course we may have multiple subdirectories,
        // containing modules, templates etc. We have to list all
        // of these contents first, and then delete them.
        fileManager.getFolderContents(path).then(contents => {
          if (contents.length > 0) {
            for (let content of contents) {
              fileManager.deleteObjects(content.key).then(() => {
              });
            }
          }
        });

        this.showLoader = true;
        // at the same time we can perform the deletion process of the
        // entry inside the dynamodb table
        this.$store.dispatch("postRequest",{
          "param":"deleteCourse",
          "payload":course
        }).then(() => {
          this.showLoader = false;
          // accessing the store mutations and splicing the course
          this.$store.commit("deleteCourse",course);

          // Iterating through recent clicked courses and splicing it, if it exists.
          for (let courseId of this.recentCourseList) {
            const index = this.recentCourseList.indexOf(courseId);
            if (course.id === courseId) {
              this.recentCourseList.splice(index, 1);
              localStorage.setItem("recentCoursesIds", JSON.stringify(this.recentCourseList));
            }
          }
        });
        this.showSuccessMessage(this.$t('course') + ' ' + this.$t('deleteSuccess'));
      });
    },

    sort(s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortBy = s;
    },

    courseClicked(item) {
      //Checking if we are inside an iFrame. If yes we don't push into the SingleCourseView.
      if(window.location === window.parent.location){
        this.$router.push({
              path: '/library/course/:id',
              name: 'Course Id',
              params: item
            }
        );
      }
      // Setting the entire link to the course into localStorage, so we can access that course on the 3D-Learning room.
      localStorage.setItem("courseId",this.domainHref + "/#/library/course/"+item.id);
      //Iterating through recent courses list
      //splicing the clicked course if it was in the list
      //before pushing it into the list.
      for (let courseId of this.recentCourseList) {
        const index = this.recentCourseList.indexOf(courseId);
        if (item.id === courseId) {
          this.recentCourseList.splice(index, 1);
        }
      }

      this.recentCourseList.push(item.id);

      window.localStorage.setItem("recentCoursesIds", JSON.stringify(this.recentCourseList));

    },

    switchSelect(event) {
      this.selected = event.target.value;
    },

    switchSort(event) {
      this.sortBy = event.target.value;
    },

    changeSorting() {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    },
    minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
    },
    isSearchResultEmpty(payload){
      this.searchResultIsEmpty = payload.length === 0;
    }
  },

  computed: {
    getDomainName(){
      const organization = this.$store.getters.company;
      if (Config.Names.domain.toLowerCase() !== "gleason"){
        return "codevance";
      } else if (organization.toLowerCase().includes("gpm")){
        return "gleason";
      } else {
        return "gleason_ch";
      }
    },

    role(){
      return this.$store.getters.role;
    },

    courses(){
      return this.$store.getters.courses;
    },

    tableHeaders(){
      return [{label:this.$t('form.title')},{label:this.$t('form.duration')},{label:this.$t('form.publicationStatus')},{label:"Tags"}];
    },

    tableRows(){
      let data = [];

      for(let course of this.courses){
        const id = course.id;
        const courseName = course.course.title;
        const courseDuration = course.course.duration ? this.minTwoDigits(course.course.duration) : '';
        const coursePublicationStatus = course.progress ? course.progress.status : this.$t('open');
        const courseTags = course.course.tags;

        let cells = [];

        cells.push(courseName,courseDuration,coursePublicationStatus);

        data.push({"id":id,"title":courseName,"tags":courseTags,cells:cells})
      }
      return data;
    },
    recentCourses() {
      if (localStorage.getItem("recentCoursesIds") !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.recentCourseList = JSON.parse(localStorage.getItem("recentCoursesIds"));
      }
      let list = [];
      if (this.recentCourseList) {
        for (let courseId of this.recentCourseList) {
          for (let course of this.courses) {
            if (courseId === course.id) list.push(course);
          }
        }
      }
      this.isSearchResultEmpty(list);
      return list.reverse();
    },

    // Displaying all Courses that have the same group as the user
    displayGroupCourses(){
      let courses = [];
      for(let course of this.courses){
        for(let group of course.course.groups) {
          if (this.$store.getters.group === group) {
            courses.push(course);
          }
        }
      }
      this.isSearchResultEmpty(courses);
      return courses;
    },

    resultQuery() {
      if (this.searchQuery) {
        return this.sortedCourseList.filter(item => {
          if (item.course.tags) {
            return item.course.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                item.course.tags.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
          } else {
            return item.course.title.toLowerCase().includes(this.searchQuery.toLowerCase());
          }
        })
      } else {
        return this.sortedCourseList;
      }
    },

    sortedCourseList() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.searchResultIsEmpty = false;
      if (this.sortBy === 'recent') return this.recentCourses;

      if(this.sortBy === 'groups') return this.displayGroupCourses;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.courses.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === 'desc') modifier = -1;

        // if we sort by duration we have to parse the string as int.
        if (this.sortBy === "duration"){
          if (parseInt(p1.course[this.sortBy]) < parseInt(p2.course[this.sortBy])) return -1 * modifier;
          if (parseInt(p1.course[this.sortBy]) > parseInt(p2.course[this.sortBy])) return modifier;
        } else {
          if (p1.course[this.sortBy] < p2.course[this.sortBy]) return -1 * modifier;
          if (p1.course[this.sortBy] > p2.course[this.sortBy]) return modifier;
        }

        return 0;
      });
    },
  },

  mounted() {
    if (localStorage.getItem("recentCoursesIds") !== null) {
      this.recentCourseList = JSON.parse(localStorage.getItem("recentCoursesIds"));
    }

    this.$store.dispatch("getRequest","getUsers");

    if(this.role) if (this.role.toLowerCase() === "admin" || this.role.toLowerCase() === "admin_light") this.isAdmin = true;
  }
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

select {
  background-color: var(--bright);
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-top: 12px;
}

.course-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.course-header-container select {
  border: 1px solid var(--brightgrey);
}

select:focus {
  outline: none;
}

select {
  padding: 5px 10px;
  border-radius: 4px;
}

/*noinspection CssUnusedSymbol*/
.asc:after {
  content: "\25B2";
  font-size: 10px;
}

/*noinspection CssUnusedSymbol*/
.desc:after {
  content: "\25BC";
  font-size: 10px;
}

.sorting-container {
  margin-right: 24px;
  margin-left: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sorting-container svg:hover {
  cursor: pointer;
  opacity: 0.5;
}

select:hover, input:hover {
  cursor: pointer;
}

.course-header-wrapperLeft {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}

/*table styling*/

.delete-icon svg:hover {
  fill: red;
  cursor: pointer;
}

.edit-icon svg:hover {
  fill: var(--primary-color);
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
}

input {
  height:32px;
}

th, td {
  text-align: left;
  padding: 10px;
}

th {
  border-bottom: 2px solid var(--darkgrey);
}

tr {
  border-bottom: 1px solid var(--brightgrey);
}

.search-result-empty{
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}

/*search filter styling*/
input {
  outline: none;
  border: none;
  min-width: 130px;
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type=search] {
  background: url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px var(--brightgrey);
  padding: 5px 10px 5px 32px;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

input[type=search]:focus {
  border-color: var(--primary-color);
}

input:-moz-placeholder {
  color: #999;
}

input::-webkit-input-placeholder {
  color: #999;
}

input[type=search] {
  padding-right: 10px;
  color: black;
  cursor: pointer;
}

input[type=search]:hover {
  background-color: #fff;
}

input[type=search]:focus {
  color: #000;
  background-color: #fff;
  cursor: auto;
}

.direction-icon-container svg {
  width: 18px;
  fill: #555;
  position: absolute;
  left: -25px;
  top: -13px;
}

.course-header-container select {
  height: 45px;
}


@media screen and (max-width: 820px) {

  .sorting-container{
    margin-left:0;
  }
  .course-header-container {
    display: block;
  }

  .course-header-wrapperLeft {
    justify-content: left;
  }

  .direction-icon-container svg {
    position: revert;
  }
}

@media screen and (max-width: 600px) {

  .course-header-wrapperLeft div {
    margin-bottom: 10px;
  }

  input, select {
    height: 48px;
  }

  .course-header-wrapperLeft {
    display: flex;
    flex-direction: column-reverse;
  }

  .course-header-container select {
    margin-right: 0;
  }

  .course-header-container {
    display: block;
  }

  input[type=search] {
    width: 100% !important;
    box-sizing: border-box;
  }

  .card-container {
    display: flex;
    flex-direction: column;
  }

  #course-view {
    width: 100%;
    height: 48px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 420px) {

}


</style>
