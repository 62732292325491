<template>
  <div>
    <div v-if="!userIsConfirmed && !this.isNewPerson" style="margin-top: 20px; margin-bottom: 20px">
      <div style="display: flex; gap:10px">
        <span>{{$t('emailConfirmationHint')}}</span>
        <a @click="this.convertToCodeUser()">{{$t('convertToCodeUser')}}</a>
      </div>
    </div>
    <div class="form-container">
      <div class="form-wrapper-top">
        <div class="form-wrapper-right">
          <label>{{ $t('form.group') }}</label>
          <VueMultiselect
              v-model="group"
              mode="single"
              :placeholder="$t('pleaseSelect')"
              :options="groupNameOptions"
              @select="groupChanged"
          />
        </div>
        <div class="form-wrapper-left">
          <AppInput :hasError="v$.friendlyName.$error" :disabled="!isNewPerson" :value="this.friendlyName" @input-changed="friendlyNameChanged" type="text" :label="$t('form.person.friendlyName')" :placeholder="this.$t('placeholderFriendlyName')"/>
        </div>
      </div>
      <div>
        <div v-if="!registerWithCode">
            <AppInput :hasError="v$.email.$error" :disabled="!isNewPerson" :value="this.email" @input-changed="emailChanged" type="email" :label="$t('form.person.email')" :placeholder="this.$t('placeholderEnterEmail')"/>
        </div>
        <div>
          <AppInput :hasError="v$.persNum.$error" :value="this.persNum" @input-changed="persNumChanged" type="text" :label="$t('form.person.persNum') + ' ' + '(optional)'" :placeholder="this.$t('placeholderPersNum')"/>
        </div>
        <div style="position: relative; width: 100%; margin-right: 20px;">
          <label>{{ $t('form.person.role') }}</label>
          <VueMultiselect
              :disabled="registerWithCode"
              v-model="role"
              mode="single"
              :placeholder="$t('pleaseSelect')"
              :options="roleData"
              @select="roleChanged"
          />
        </div>
        <div style="margin-top: 20px; display: flex; gap:20px" v-if="isNewPerson">
          <label>{{$t('registerWithCode')}}</label>
          <toggle-btn :default-state="registerWithCode" @toggle-changed="registerWithCodeChanged" id="2"
                      :label-disable-text="$t('no')" :label-enable-text="$t('yes')"></toggle-btn>
        </div>
      </div>
    </div>
    <div class="form-btm-wrapper primary-btn-wrapper">
      <primary-btn @click="updatePerson(this.isNewPerson)" type="submit" :val="$t('confirm')"></primary-btn>
    </div>
  </div>
</template>
<script>

import AppInput from "../../form/inputs/AppInput";
import PrimaryBtn from "../../buttons/PrimaryBtn";
import VueMultiselect from "vue-multiselect";
import Uuidv4Generator from "../../../mixins/uuid/Uuidv4Generator";
import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import ToggleBtn from "@/components/toggle/ToggleBtn";
import SweetAlerts from "../../../mixins/sweet-alerts/SweetAlerts";
import {Config} from "../../../config";

export default {
  name: 'AddPersonForm',
  mixins: [Uuidv4Generator, SweetAlerts],
  emits:['person-updated'],
  components: {
    ToggleBtn,
    AppInput,
    PrimaryBtn,
    VueMultiselect
  },
  setup(){
    return{
      v$: useVuelidate()
    }
  },

  data(){
    return {
      friendlyName: this.person.name ? this.person.name : '',
      persNum: this.person.persNum ? this.person.persNum : '',
      email: this.person.email ? this.person.email : '',
      group: this.person.group ? this.person.group : '',
      role: this.person.role ? this.person.role : '',
      groupData: [],
      roleData: ["user", "admin_light", "admin"],
      registerWithCode:false,
      userIsConfirmed: true
    }
  },

  validations(){
     if(this.registerWithCode) {
        return {
          friendlyName: {required},
          persNum: {},
          group: {required},
        }
     }else {
        return {
          friendlyName: {required},
          persNum: {},
          email: {required, email},
          group: {required},
          role: {required}
        }
     }
  },

  props:{
    person: Object,
    isNewPerson: Boolean
  },

  computed:{
      groupNameOptions(){
        let options = [];
        for (let object of this.groupData){
          options.push(object.group.name);
        }
        return options;
      },
      getDomainName(){
        const organization = this.$store.getters.company;
        if (Config.Names.domain.toLowerCase() !== "gleason"){
          return "codevance";
        } else if (organization.toLowerCase().includes("gpm")){
          return "gleason";
        } else {
          return "gleason_ch";
        }
      },
  },
  methods: {
    cancelForm(){
      this.$emit("close-form");
    },

    emitPersonUpdatedEvent(personData,isNewPerson){
      this.$emit("person-updated",{data:personData, isNewPerson: isNewPerson});
    },

    convertToCodeUser(){
      this.showConfirmDialogue(this.$t('convertToCodeUser'),
          this.$t('swal.confirmDelete')).then(()=> {
        const obj = {id: this.person.id}
        this.$store.dispatch("deleteUser", obj).then(() => {
          this.registerWithCode = true;
          this.updatePerson(true);
        })
      })
    },

    /**
     * gets the status of a certain user to see if the user is confirmed
     * or not.
     * @returns {Promise<unknown>}
     */
    getUserStatus(){
      return new Promise((resolve)=>{
        this.$store.dispatch("getRequest","getUserStatus&query=" + this.person.id).then(resp => {
          const data = JSON.parse(resp.body);
          resolve(data);
        });
      })
    },

    updatePerson(isNewPerson){
      let personData;
        // now we can inform our parent and then close the form.
      if(isNewPerson){
        this.v$.$validate();
        if(!this.v$.$error) {
          const codeId = this.registerWithCode ? this.createUuidv4().slice(0,7) : this.createUuidv4();

          if(this.registerWithCode) {
            this.email = codeId + "@learningonetimeuser.gl";
          }

          personData = {
            "id": codeId,
            "friendlyName": this.friendlyName,
            "persNum": this.persNum,
            "email": this.email.toLowerCase(),
            "group": this.group,
            "role": this.role,
            "code": this.registerWithCode,
            "domain": this.getDomainName
          }
        }
      } else{
        this.v$.$validate();
        if(!this.v$.$error){
          personData = {
            "id": this.person.id,
            "friendlyName":this.friendlyName,
            "persNum":this.persNum,
            "email": this.email,
            "group": this.group,
            "role": this.role,
            "domain": this.getDomainName
          }
        }
      }

      if(!this.v$.$error){
        this.emitPersonUpdatedEvent(personData, isNewPerson);
        this.cancelForm();
      }
    },

    getGroupOptions(){
      return new Promise((resolve)=>{
        this.$store.dispatch("getRequest","getGroups").then(resp => {
          resolve(JSON.parse(resp.body));
        });
      });
    },

    friendlyNameChanged(event){
      this.friendlyName = event.target.value;
    },
    persNumChanged(event){
      this.persNum = event.target.value;
    },
    emailChanged(event){
      this.email = event.target.value;
    },
    groupChanged(group){
      this.group = group;
    },
    roleChanged(role){
      this.role = role;
    },
    registerWithCodeChanged(trueOrFalse){
      this.registerWithCode = trueOrFalse;
      this.role = this.registerWithCode ? "user" : "";
    },
  },

  mounted() {
    this.getGroupOptions().then((groupOptions => {
      this.groupData = groupOptions;
    }));

    /**
     * For users that are not confirmed yet, we offer the possibility
     * of converting them into code users only.
     */
    if (!this.isNewPerson){
      this.getUserStatus().then(status => {
        if (status !== "CONFIRMED") this.userIsConfirmed = false;
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

a{
  text-decoration: none;
  color: var(--primary-color);
}

.form-wrapper-top{
  display: flex;
}

.form-wrapper-left{
  position: relative;
  width: 100%;
  margin-left: 20px;
}

.form-wrapper-right{
  position: relative;
  width: 100%;
  margin-right: 20px;
}

/*noinspection CssUnusedSymbol*/
.errorBorder{
  border-color: red;
}

.form-container{
  padding: 40px;
  background-color: var(--brightgrey);
  border-radius: 4px;
  margin-bottom: 24px;
}

.form-btm-wrapper{
  display: flex;
  justify-content: right;
}

label{
  font-weight: 300;
}

input:focus, select:focus, textarea:focus-visible, textarea:focus {
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

input, select, textarea{
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--brightgrey);
  background-color: var(--bright);
}

input:focus + label,
select:focus + label,
textarea:focus + label {
  color: var(--primary-color);
}

.primary-btn-wrapper{
  position: fixed;
  right: 64px;
  bottom: 64px;
}

@media screen and (max-width: 800px) {
  .form-wrapper-top{
    display: block;
  }

  .form-wrapper-left{
    margin-left: 0;
  }

  .form-wrapper-right{
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 600px) {
  .form-container{
    padding: 0;
    background-color: transparent;
  }

  .primary-btn-wrapper{
    right: 40px;
    bottom: 40px;
  }
}

@media screen and (max-width: 600px) {

}

</style>
