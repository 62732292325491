<template>
  <Modal :val="this.$t('back')" @close-form="this.emitModalClosed" :heading="$t('learningProgress')" :modal-is-open=this.modalIsOpen>
    <div style="display: flex; align-items: center; justify-content: start">
      <initials-avatar :initials="this.initials"></initials-avatar>
      <div style="margin-left:10px">
        <span>{{this.friendlyName}}</span>
      </div>
    </div>
    <div class="button-container">
       <PrimaryBtn :val="this.$t('restartCourse')" @click="restartCourse"></PrimaryBtn>
     </div>

    <ContentPreviewContainer @template-clicked="(module, template)=>this.openTemplate(template, module)"
                             :isEditorView="false" :course="this.course" :learningProgress="this.courseProgress">
    </ContentPreviewContainer>

  </Modal>
</template>

<script>
import InitialsAvatar from "@/components/avatar/InitialsAvatar";
import Modal from "@/components/modal/Modal";
import ContentPreviewContainer from "@/components/content-preview-container/content-preview-container";
import {S3FileManager} from "@/mixins/s3-file-manager/s3-file-manager";
import SweetAlerts from "@/mixins/sweet-alerts/SweetAlerts";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";

export default {
name: "CourseProgressInfo",
components: {
  ContentPreviewContainer, InitialsAvatar, Modal,PrimaryBtn
},
  mixins:[SweetAlerts],
  props:{
    modalIsOpen: Boolean,
    course: Array,
    courseProgress: Number,
    friendlyName: String,
    selectedPersonId: String,
    userCourses: Array,
    selectedCourseData:Array,
  },

  data(){
    return{
      courseProgressList: null,
      name: null,
      isTemplateOpen: false,
      activeTemplate: Object,
      // the active module, which serves as template container
      activeModule: Object,
      s3KeyList: [],
      attempts: 0,
    }
  },

  computed: {
    initials() {
      return this.name ? this.name.substring(0, 2).toUpperCase() : "";
    },
  },

  methods:{
    emitModalClosed(){
      this.$emit("modal-closed");
    },
    openTemplate(template, module){

      // if we select a template that contains a question or a
      // user certificate, we will show a information popup,
      // otherwise we will show nothing.

      if(template.type === "userCertificate"){
        this.$swal({
          title: this.$t('showCertificate'),
          icon:"warning",
          confirmButtonColor: "#007AC2",
          confirmButtonText: this.$t('confirm'),
          showCancelButton: true,
          cancelButtonText: this.$t('button.cancel'),
        }).then((res) => {
          if(res.isConfirmed){
            // if the user clicks confirm we get the file path and open a new window where the user can download the file.

            const path = "course/" + this.course.id + "/" + module.id + "/" + template.id + "/userInput/" + this.selectedPersonId + "/" ;
            const s3FileManager = new S3FileManager();
              this.listFileObjects(path).then(objects => {
                for(let object of objects){
                  s3FileManager.getSignedURL(object.key).then((url) => {
                    if (Object.keys(objects).length !== 0) {
                      window.open(url,object.key);
                    } else {
                      this.showWarningMessage(this.$t('certificateNotUploaded'));
                    }
                  })
                }
              })
          }
        })
      }

      // if the template is a question or imageMarker, we return the status of the question.
      if(template.type === "question" || template.type === "imageMarker"){
        for(let course of this.userCourses){
          if(this.course.id === course.learningStatus.id){
            for(let question of course.learningStatus.questions){
              if(template.id === question.id){
                if(question.status === "passed"){
                  this.showSuccessMessage(question.status);
                }else if(question.status === "failed"){
                  this.showErrorMessage(question.status);
                }else{
                  this.showWarningMessage(question.status);
                }
              }
            }
          }
        }
      }
    },
    closeGallery() {
      this.isTemplateOpen = false;
    },

    listFileObjects(prefix) {
      return new Promise((resolve => {
        const s3FileManager = new S3FileManager();
        s3FileManager.checkIfListExists(prefix).then(res => {
          resolve(res);
        })
      }))
    },

    restartCourse(){
      for(let course of this.userCourses){
        if(course.courses.id === this.course.id){
          // old courses might not have a max attempts key. If we have a course like this,
          // then we set the max attempts by default to 5.
          this.attempts = course.courses.rating.maxAttempts ? course.courses.rating.maxAttempts : 5;
        }
      }
      this.$swal({
        title: this.$t('restartCourse'),
        text: this.$t('restartCourseCompletely'),
        icon: 'warning',
        confirmButtonColor: "#007AC2",
        confirmButtonText: this.$t('confirm'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        }).then((res) => {
          if (res.isConfirmed) {

            const postObject = {
              "courseId": this.course.id,
              "userId": this.$store.getters.username,
              "learningProgress": 0,
              "remainingRestarts": this.attempts,
              "subscriptionTimestamp":this.selectedCourseData.progress.subscriptionTimestamp,
            }
            this.$store.dispatch("postRequest", {
              "param": "restartCourse",
              "payload": postObject
            }).then(()=>{
              this.showSuccessMessage(this.$t('resetSuccess'));
              this.$store.commit("updateLearningProgress",postObject);

              this.emitModalClosed();
            });
          }
        });
    },
  },

  watch:{
    course(){
      if (this.course){
        this.courseProgressList = this.course;
      }

      if (this.friendlyName){
        this.name = this.friendlyName;
      }
    }
  }
}
</script>

<style scoped>

.button-container{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
